<template>
  <section id="keys">
    <div class="page">
      <form class="card" @submit.prevent="openConfirmationModal">
        <div class="grid-x grid-margin-x">
          <div class="cell auto">
            <app-label>Your API key</app-label>
          </div>
        </div>
        <div class="grid-x row grid-margin-x input-container">
          <div class="cell auto">
            <div class="grid-x">
              <div class="cell auto">
                <app-input class="hidden-key text" v-if="showApiKey" v-model="apiKey" disabled />
                <app-input class="hidden-key" v-else :value="'Your key is hidden'" disabled />
              </div>
              <div class="cell shrink" v-if="showApiKey">
                <app-button @click="copyApiKey" class="copy" title="Copy API key to clipboard">
                  <copy/>
                </app-button>
                <div class="tooltip" :class="{ 'show': showApiKeyTooltip }">
                  <copy /><p>Copied to you clipboard !</p>
                </div>
              </div>
            </div>
          </div>
          <div class="cell shrink">
            <app-button @click="showApiKey = !showApiKey" class="icon-button" :aria-label="showApiKey ? 'Hide API key' : 'Show API key'" :title="!isApiKeyAvailable && 'A credit card is needed to show the API Key'">
              <hide v-if="showApiKey" />
              <eyeOpen v-else />
            </app-button>
          </div>
        </div>
        <div class="grid-x grid-margin-x">
          <div class="cell auto">
          </div>
          <div class="cell shrink">
            <app-button type="submit" size="large" :title="'Refresh API Key'">Generate/Refresh</app-button>
          </div>
        </div>
      </form>
      <div class="card">
        <div class="grid-x grid-margin-x">
          <div class="cell auto">
            <app-label>Webhook payload signature</app-label>
          </div>
        </div>
        <div class="grid-x row grid-margin-x input-container">
          <div class="cell auto">
            <div class="grid-x">
              <div class="cell auto">
                <app-input class="hidden-key text" v-if="showSecretSignature" v-model="secretSignature" disabled/>
                <app-input class="hidden-key" v-else value="Your secret signature is hidden" disabled />
              </div>
              <div class="cell shrink" v-if="showSecretSignature">
                <app-button @click="copySecretSignature" class="copy">
                  <copy />
                </app-button>
                <div class="tooltip" :class="{ 'show': showSecretSignatureTooltip }">
                  <copy /><p>Copied to you clipboard !</p>
                </div>
              </div>
            </div>
          </div>
          <div class="cell shrink">
            <app-button @click="showSecretSignature = !showSecretSignature" class="icon-button">
              <hide v-if="showSecretSignature"/>
              <eyeOpen v-else />
            </app-button>
          </div>
        </div>
        <div class="grid-x grid-margin-x help">
          <div class="cell shrink">
            <help :class="{ 'eye-open': !showApiKey }" />
          </div>
          <div class="cell auto">
            <p>Enforcing integrity : In webhooks you receive from us, you can validate the payload according to the header x-doppio-payload-signature.</p>
            <a :href="`${documentationUrl}/guide/cookbook/webhook-signature.html`" target="_blank">How to check your webhook payload</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import userApi from '@/services/api/user';
import hide from '@/assets/img/hide.svg?inline';
import eyeOpen from '@/assets/img/eye-open.svg?inline';
import copy from '@/assets/img/copy.svg?inline';
import help from '@/assets/img/help.svg?inline';

export default {
  name: 'keys',
  components: {
    hide,
    eyeOpen,
    copy,
    help,
  },
  props: {
    isCardActive: {
      type: Boolean,
    },
  },
  data() {
    return {
      apiKey: null,
      showApiKey: false,
      showApiKeyTooltip: false,

      secretSignature: null,
      showSecretSignature: false,
      showSecretSignatureTooltip: false,

      documentationUrl: process.env.VUE_APP_DOC_URL,
    };
  },
  computed: {
    isApiKeyAvailable() {
      return this.isCardActive;
    },
  },
  async mounted() {
    try {
      const user = await userApi.getMe();
      this.apiKey = user ? user.apiKey : null;
      this.secretSignature = user ? user.secretSignature : null;
    } catch (er) {
      this.$message.show({
        title: 'Error',
        text: 'Impossible to get your informations',
        confirmText: 'Ok',
        hasCancel: false,
      });
    }
  },
  methods: {
    copyApiKey() {
      navigator.clipboard.writeText(this.apiKey);
      this.showApiKeyTooltip = true;
      setTimeout(() => { this.showApiKeyTooltip = false; }, 3000);
    },
    copySecretSignature() {
      navigator.clipboard.writeText(this.secretSignature);
      this.showSecretSignatureTooltip = true;
      setTimeout(() => { this.showSecretSignatureTooltip = false; }, 3000);
    },
    openConfirmationModal() {
      this.$message.show({
        title: 'Refresh API key',
        text: 'Are you sure you want to refresh the API key? Please note that your old API key will no longer be activated.',
        confirmText: 'Confirm',
        cancelText: 'Cancel',
        onConfirm: () => this.refreshApiKey(),
        hasCancel: true,
      });
    },
    async refreshApiKey() {
      try {
        const newKeys = await userApi.generateKey();
        this.apiKey = newKeys.apiKey;

        this.$notification.show({
          text: 'API key generated successfully!',
        });
      } catch (error) {
        this.$message.show({
          title: 'Error',
          text: 'Impossible to generate your API key',
          confirmText: 'Ok',
          hasCancel: false,
          onConfirm: () => {},
        });

        throw error;
      }
    },
  },
};
</script>
<style lang="sass">
#keys
  .page
    @include page
    h1
      color: $white
      margin-bottom: 30px
      svg
        margin-right: 10px
    p
      margin-top: 30px
    .row
      margin-bottom: 20px
      align-items: flex-end
      position: relative
    .card
      background-color: $primary90
      border-radius: 8px
      padding: 40px
      margin-bottom: 40px
      p
        margin: 0
      .input-container
        align-items: stretch
      .icon-button, .copy
        margin-top: 20px
        margin: 0
        display: flex
        align-items: center
        justify-content: center
        padding: 0 9px
        height: 100%
        width: 40px
        svg
          width: 16px
      .copy
        background-color: $primary70
        border-radius: 4px
        border-top-left-radius: 0px
        border-bottom-left-radius: 0px
        outline: none
        &:focus
          border: 1px solid $primary
        svg
          width: 15px
          margin-right: 5px
      .hidden-key
        input
          border-top-right-radius: 0px
          border-bottom-right-radius: 0px
        &.text
          color: $white
      .tooltip
        position: absolute
        background-color: $primary70
        width: 220px
        padding: 10px
        right: -30px
        top: -50px
        display: flex
        align-items: center
        jusitfy-content: center
        border-radius: 8px
        opacity: 0
        transition: opacity 0.3s ease-out, transform 0.4s ease-out
        transform: translateY(10px)
        svg
          width: 20px
          margin-right: 10px
        &.show
          opacity: 1
          transform: translateY(0px)
      .help
        div
          margin-right: 0
          padding-top: 10px
          p
            color: $primary30
          a
            color: $accent100
            text-decoration: underline
            font-size: 12px
</style>
